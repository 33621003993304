import React, { useState, cloneElement } from 'react';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../../frontend/components/Sidebar';

function LayoutFrontend({ children }) {

    
    const [isOpen, setIsOpen] = useState(false);

    const [activeTab, setActiveTab] = useState('Girlfriend');
    
    const toggleSidebar = () => {
        setIsOpen(prev => !prev);
    };

    const handleActiveTab = (data) => {
        setActiveTab(data);
    };


    return (
        <div>
            <header>
                <Sidebar isOpen={isOpen} />
            </header>
            <div>
                <div className="main-container float-start w-full">
                    <DashboardHeader toggleSidebar={toggleSidebar} isOpen={isOpen} activeTab={activeTab} setActiveTab={handleActiveTab}/>
                    <div className='mt-[90px] ml-[70px] min-h-[52rem]'>
                        {React.Children.map(children, child =>
                            cloneElement(child, { activeTab, handleActiveTab })
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
}
export default LayoutFrontend;
