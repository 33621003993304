import React, { useState } from 'react';
import { advanceImg, recycle, starImg, videoImg } from '../../../assets';
import Simplified from './Simplified';
import AdvancePromopt from './AdvancePromopt';
import VideoPage from './VideoPage';
import ReplicatePose from './ReplicatePose';

const LandingPageFrontend = () => {
    const [activeComponent, setActiveComponent] = useState('Simplified');

    return (
        <>
            <div className='mt-20 px-5 xl:px-20 pb-20 bg-white pt-10 text-black font-josefin-sans'>
                <div>
                    <p className='text-[35px] py-2 text-black'>Generate New</p>
                    <div className='md:rounded-tr-xl md:rounded-br-xl md:rounded-tl-xl md:rounded-bl-xl md:flex justify-between lg:text-xl bg-red-600'>
                        <button
                            className={`w-full flex md:rounded-tl-xl md:rounded-bl-xl items-center gap-10 md:gap-5 px-3 xl:px-10 py-3 ${activeComponent === 'Simplified' ? 'bg-red-800' : 'bg-red-600 hover:bg-red-800'} md:justify-center ps-12 md:ps-0`}
                            onClick={() => setActiveComponent('Simplified')}
                        >
                            <img src={starImg} alt="starImg" />
                            <p className='text-white'>Simplified</p>
                        </button>
                        <button
                            className={`w-full flex items-center gap-10 md:gap-5 px-3 xl:px-10 py-3 ${activeComponent === 'AdvancePromopt' ? 'bg-red-800' : 'bg-red-600 hover:bg-red-800'} md:justify-center ps-12 md:ps-0`}
                            onClick={() => setActiveComponent('AdvancePromopt')}
                        >
                            <img src={advanceImg} alt="advanceImg" className='h-8' />
                            <p className='text-white'>Advance Promopt</p>
                        </button>
                        <button
                            className={`w-full flex items-center gap-10 md:gap-5 px-3 xl:px-10 py-3 ${activeComponent === 'VideoPage' ? 'bg-red-800' : 'bg-red-600 hover:bg-red-800'} md:justify-center ps-12 md:ps-0`}
                            onClick={() => setActiveComponent('VideoPage')}
                        >
                            <img src={videoImg} alt="videoImg" />
                            <p className='text-white'>Video</p>
                        </button>
                        <button
                            className={`w-full flex md:rounded-tr-xl md:rounded-br-xl items-center gap-10 md:gap-5 px-3 xl:px-10 py-3 ${activeComponent === 'ReplicatePose' ? 'bg-red-800' : 'bg-red-600 hover:bg-red-800'} md:justify-center ps-12 md:ps-0`}
                            onClick={() => setActiveComponent('ReplicatePose')}
                        >
                            <img src={recycle} alt="recycle" />
                            <p className='text-white'>Replicate Pose</p>
                        </button>
                    </div>
                </div>

                {activeComponent === 'Simplified' ? <Simplified /> :
                    activeComponent === 'AdvancePromopt' ? <AdvancePromopt /> :
                        activeComponent === 'VideoPage' ? <VideoPage /> :
                            <ReplicatePose />}

            </div>
        </>
    )
}

export default LandingPageFrontend;
