import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoThumbsDownOutline, IoThumbsUpOutline, IoVolumeHighSharp } from 'react-icons/io5';
import axios from 'axios';

const BASE_URL = "https://api2.eromantic.ai/assistant_api/";

const Aidreamchat = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [assistants, setAssistants] = useState([]);
    const [message, setMessage] = useState('');
    const [assistant_id, setActiveAssistantId] = useState(null);
    const [characterid, setCharacterId] = useState(null);
    const [isLoadingAIResponse, setIsLoadingAIResponse] = useState(false);
    const [activeAssistantName, setActiveAssistantName] = useState('');
    const [messagesHistory, setMessagesHistory] = useState([]);
    const [messages, setMessages] = useState([]);
    const [userimg, setUserimg] = useState('');
    const u_id = localStorage.getItem('u_id');
    const scrollbarRef = useRef();

    const NewGenerate = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${BASE_URL}bot_fetch/?user_id=${u_id}`);
            if (response.status === 200) {
                console.log(response.data, "============");
                // console.log();
                setUserimg(response.data?.data[response.data.data.length - 1].Image)
                setAssistants(response.data.data);
                setActiveAssistantId(response.data.data[0].assistant_id);
                setActiveAssistantName(response.data.data[0].name);
                setCharacterId(response.data.data[0].id);
            } else {
                setError('Unexpected response from the server.');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        NewGenerate();
    }, []);


    useEffect(() => {
        scrollToBottom();
    }, [messagesHistory, messages]); // This will trigger scrolling every time 'mymessages' changes

    const scrollToBottom = () => {
        setTimeout(() => {
            if (scrollbarRef.current) {
                scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight;
            }
        }, 100); // Delay might be needed to wait for the DOM to update
    };


    useEffect(() => {
        // Fetch old messages when character_id changes
        const fetchOldMessages = async () => {
            if (characterid) {
                try {
                    const response = await axios.get(`${BASE_URL}fetch_history/?character_id=${characterid}`);
                    if (response.status === 200 || response.status === 201) {
                        const oldMessages = response.data || [];
                        const formattedMessages = [];

                        // Create an array of message objects
                        oldMessages.forEach(msg => {
                            if (msg.user_message) {
                                formattedMessages.push({
                                    sender: 'user',
                                    text: msg.user_message || 'No message',
                                    time: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                                });
                            }
                            if (msg.ai_response) {
                                formattedMessages.push({
                                    sender: 'ai',
                                    text: msg.ai_response || 'No response',
                                    time: new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                                });
                            }
                        });

                        // Sort messages by timestamp to ensure proper order
                        formattedMessages.sort((a, b) => new Date(a.time) - new Date(b.time));

                        // Update messages history
                        setMessagesHistory(formattedMessages);
                    } else {
                        console.error("Unexpected response status:", response.status);
                    }
                } catch (error) {
                    console.error('Error fetching message history:', error);
                }
            }
        };

        fetchOldMessages();
    }, [characterid]);

    const getCurrentTime = () => {
        const now = new Date();
        return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleClick = async (assistantId, assistantName, id, assistantImage) => {
        setActiveAssistantId(assistantId);
        setActiveAssistantName(assistantName);
        setUserimg(assistantImage || "/static/media/img1.9322f9a528d87450122a.png")
        const effectiveId = id !== undefined ? id : characterid;
        setCharacterId(effectiveId);
    };

    const SendMsg = async () => {
        if (!assistant_id || !message.trim()) {
            console.warn("Assistant ID or message is not valid");
            return;
        }
        const userMessage = message;
        setMessages((prevMessages) => [
            ...prevMessages,
            {
                text: message,
                sender: "user",
                time: getCurrentTime()
            },
        ]);
        setMessage('');
        setIsLoadingAIResponse(true); // Set loading state

        const payload = { assistant_id, message: message };
        try {
            const response = await axios.post(`${BASE_URL}bot_chat/`, payload);
            if (response.status === 200 || response.status === 201) {
                const aiResponseMessage = response.data.response_message;
                setMessages((prev) => [
                    ...prev,
                    {
                        text: aiResponseMessage,
                        sender: "bot",
                        time: getCurrentTime()
                    },
                ]);
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoadingAIResponse(false); // Reset loading state
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && message.trim()) {
            SendMsg();
        }
    };
    useEffect(() => {
        // Fetch old messages when character_id changes
        const fetchOldMessages = async () => {
            if (characterid) {
                try {
                    const response = await axios.get(`${BASE_URL}fetch_history/?character_id=${characterid}`);
                    if (response.status === 200 || response.status === 201) {
                        console.log("Old messages res=========", response.data);

                        // Update messages history
                        setMessagesHistory(response.data);
                    } else {
                        console.error("Unexpected response status:", response.status);
                    }
                } catch (error) {
                    console.error('Error fetching message history:', error);
                }
            }
        };

        fetchOldMessages();
    }, [characterid]); //

    return (
        <div className='bg-white text-white font-josefin-sans min-h-[53rem]'>
            <div className="grid grid-cols-12 border border-[#9F9D9D] rounded-lg min-h-[50rem]">
                <div className="col-span-2 border border-[#9F9D9D] rounded-tl-lg rounded-bl-lg custm-bg-chat">
                    <div className="flex items-center justify-center text-2xl min-h-[5rem]">Chat with GirlFriend</div>
                    <div className='chatnames'>
                        {loading && <p>Loading...</p>}
                        {error && <p>{error}</p>}
                        {!loading && !error && assistants.length === 0 && <p>No assistants available.</p>}
                        {!loading && !error && assistants.length > 0 && (
                            <ul>
                                {assistants.map((assistant) => (
                                    <li key={assistant.assistant_id}
                                        className='flex items-center justify-center border border-[#000] text-black rounded-xl bg-[#fff] min-h-[5rem] my-[10px] mx-[10px] p-2 cursor-pointer'
                                        onClick={() => handleClick(assistant.assistant_id, assistant.name, assistant.id, assistant?.Image)}
                                    >
                                        <div>
                                            <img
                                                src={assistant?.Image || "/static/media/img1.9322f9a528d87450122a.png"} // Use the specific assistant's image
                                                alt="userProfile"
                                                className="w-14 h-14 rounded-full"
                                            />
                                        </div>
                                        <div className='px-2'>
                                            <div className='flex items-center justify-between'>
                                                <p className="text-xl">
                                                    {assistant.name || 'Unnamed Assistant'}
                                                    <span className="inline-block w-3 h-3 bg-green-600 rounded-full mx-3 mb-[2px] border-2 border-green-600 shadow-md shadow-green-800"></span>
                                                </p>
                                                <p className="text-xl">{getCurrentTime()}</p>
                                            </div>
                                            <div style={{ width: "10rem" }}>
                                                <span className="text-sm">{assistant.message || 'No message available.'}</span>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                        )}
                    </div>
                </div>
                <div className="col-span-7 chatbackgr p-0">
                    <div className="flex items-center text-2xl px-5 border border-[#9F9D9D] rounded-tr-lg min-h-[5rem] custm-bg-head">
                        <img src={userimg} alt="userProfile" className="w-14 h-14 rounded-full mx-4" />{activeAssistantName}
                    </div>
                    <div className='h-[41rem] py-[40px] px-5 overflow-auto ' ref={scrollbarRef}>
                        {messagesHistory.length === 0 && <p>{message}</p>}
                        {messagesHistory.map((msg, index) => (
                            <>
                                <div key={index} className={`flex justify-end m-6`}>
                                    {
                                        msg.user_message ?
                                            <div className={`userchat max-w-[60%] border border-[#88ceff] flex p-2.5 pl-4`}>
                                                {msg.user_message}
                                            </div>
                                            :
                                            ""
                                    }

                                </div>
                                <div className={`flex justify-start m-6`}>
                                    <div className={`aichat max-w-[60%] border border-[#88ceff] flex p-2.5 pl-4`}>
                                        {msg.ai_response}
                                        <div>
                                            <div className='text-red-500 border bg-[#ffffff] p-[5px] mx-2 rounded-full shadow-lg'>
                                                <IoVolumeHighSharp />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                        {messages.map((msg, index) => (
                            <>
                                {msg.sender === "user" ? (
                                    <div key={index} className={`flex justify-end m-6`}>
                                        <div className={`userchat max-w-[60%] border border-[#88ceff] flex p-2.5 pl-4`}>
                                            {msg.text}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className={`flex justify-start m-6`}>
                                            <div className={`aichat max-w-[60%] border border-[#88ceff] flex p-2.5 pl-4`}>
                                                {msg.text}
                                                <div>
                                                    <div className='text-red-500 border bg-[#ffffff] p-[5px] mx-2 rounded-full shadow-lg'>
                                                        <IoVolumeHighSharp />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ))}

                        {/* Show loader dots when waiting for AI response */}
                        {isLoadingAIResponse && (
                            <div className={`aichat max-w-[20%] m-6 border border-[#88ceff] flex p-2.5 pl-6`}>
                                <div className='loaderdots'></div>
                            </div>
                        )}
                    </div>
                    <div className='flex bg-[#ffeeee] mt-2 px-5 py-3 text-black text-2xl font-light w-90 h-38'>
                        <input
                            type="text"
                            className='focus:outline-none  w-full text-black'
                            placeholder='Type Your Message...'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                        <button
                        className='ml-2 text-black focus:outline-none'
                    >
                        {/* Replace with your microphone icon, e.g., FontAwesome or SVG */}
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{background: "#fd7f8f", padding: "1px", borderRadius:"5px"}}>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14c-2.21 0-4-1.79-4-4V4a4 4 0 118 0v6c0 2.21-1.79 4-4 4z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 10v4a6 6 0 0012 0v-4" />
                        </svg>
                    </button>
                    </div>
                </div>
                <div className="col-span-3 border border-[#9F9D9D] rounded-tl-lg rounded-bl-lg custm-bg-chat">
                    <div className="flex items-center text-2xl px-5 border border-[#9F9D9D] rounded-tr-lg min-h-[5rem] custm-bg-head">
                        {activeAssistantName}
                    </div>
                    <div><img src={userimg} alt="userProfile" className="" /></div>
                </div>
            </div>
        </div>
    );
};

export default Aidreamchat;
