import { useState, useEffect } from "react";
import { RiCompassDiscoverLine, RiAiGenerate } from "react-icons/ri";
import { TbGenderDemigirl, TbPremiumRights, TbArrowGuide, TbGenderDemiboy } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";


export const Sidebar = ({ isOpen }) => {
    const [active, setActive] = useState(1);
    const [Userlogger, setUserlogger] = useState(null);
    const [usercheck, setUsercheck] = useState(null);
    const goto = index => setActive(index);
    const navigate = useNavigate();

    useEffect(() => {
        setUsercheck(localStorage.getItem('hasVisited'));
        setUserlogger(localStorage.getItem('login'));
    }, []); // Add dependency array to avoid infinite loop

    const navItems = [
        { label: "AI GIRLFRIEND", icon: <i class="fa-solid fa-venus"></i>, link: Userlogger ? "/CreateDreamGF" : "/login" },
        { label: "AI BOYFRIEND", icon: <i class="fa-solid fa-mars"></i>, link: Userlogger ? "/BoyFriend" : "/login" },
        { label: "CHAT", icon: <i class="fa-solid fa-comments"></i>, link: Userlogger ? "/Aidreamchat" : "/login" },
        { label: "DISCOVER", icon: <i class="fa-solid fa-compass"></i>, link: Userlogger ? "/dashboard/discover" : "/login" },
        { label: "GENERATIONS", icon: <i class="fa-solid fa-file-circle-plus"></i>, link: Userlogger ? "/dashboard/generations" : "/login" },
        { label: "Pricing", icon: <i class="fa-solid fa-square-poll-vertical"></i>, link: Userlogger ? "/" : "/login" },
        { label: "Referrals", icon: <i class="fa-solid fa-repeat"></i>, link: Userlogger ? "/dashboard/referrals" : "/login" },
        { label: "GUIDE", icon: <i class="fa-solid fa-circle-question"></i>, link: Userlogger ? "/dashboard/girls" : "/login" },
        { label: "Support", icon: <i class="fa-solid fa-headset"></i>, link: Userlogger ? "/dashboard/support" : "/login" },
    ];

    return (
        <>
            {usercheck ?
                <aside className={`sidebar ${isOpen ? 'sidewidth' : ''}`}>
                    <div className="inner">
                        <nav className="menu mt-3" >
                            {navItems.map((item, index) => (
                                <Link to={item.link} key={item.label} style={{ textDecoration: 'none' }}>
                                    <button
                                        className={active === index ? "active" : ""}
                                        onClick={() => goto(index)}
                                    >
                                        <span style={{ fontSize: '25px', color: '#000' }}>{item.icon}</span>
                                        <p style={{ fontSize: '20px', color: '#000' }}>{item.label}</p>
                                    </button>
                                </Link>
                            ))}
                        </nav>
                    </div>
                </aside>
                :
                ""
            }


        </>
    );
};

export default Sidebar;