import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Ani1, img1, img3, img4, img7, smalltits } from '../../../assets';
import {
    firstImage, second_ani_1, hair3, body3, img42, skinnyBody, secondThird, largeB3, hugeOo4, slim2, thick4, secondFifth, greenEye,
    secondSecond, hugeO4, sixFirstLong, hair5, img41, averageB2, averageOo2, age4, age2, hugeB4, secondSix, secondAni3,
    average2, shortHairo, longHair, img44, age3, age1, skinny1, slimBody, huge4, hair2, img45, smallO1, secondAni2,
    secondFourth, blackEye, shortHair, smallB1, large3, fifthFirst, fifthThird, fifthFourth, fifthSecond, grayEye, brownEye,
    largeO3, longHairo, averageo2, hair4, thickBody, blueEye, img43, secondAni1, largeOo3, secondFirstImage, athletic3, small1,smallai,
} from '../../../assets';
import {
    green,    brown1,    gray,    buzzCut,    muscular,    pg26,    brown2,    slim,    dreadlocks,    greenAni,    anime,    blackAni,    pg25,    curly1,
    dreadlocksAni,    white,    grayAni,    pg12,    curly2,    blueAni,    blue,    slimAni,    caucasian,    pg24,    buzzCutAni,    realistic,    brown3,
    pg21,    black1,    pg23,    black2,    boyfriendMain,    pg22,    pg11,
} from '../../../assets';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const groupData = {
    group1: null,
    group2: null,
    group3: null,
}
const BASE_URL = "https://api2.eromantic.ai/assistant_api/";

const AIBoyfriend = () => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [gfData, setGFdata] = useState('');
    const [style, setStyle] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [finalInputValue, setFinalInputValue] = useState('');

    const [selectedOptions, setSelectedOptions] = useState(groupData);
    const [optionsData, setOptionsData] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [pageSequence, setPageSequence] = useState([]);

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const navigate = useNavigate();

    const images0 = [        { src: pg11, title: 'Realistic', tab: 'Style' },        { src: pg12, title: 'Anime', tab: 'Style' }    ];
    
    const images1 = [        { src: pg21, title: 'Caucasian', tab: 'Ethnicity' },        { src: pg22, title: 'African', tab: 'Ethnicity' },
        { src: pg23, title: 'Asian', tab: 'Ethnicity' },        { src: pg24, title: 'Arabic', tab: 'Ethnicity' },
        { src: pg25, title: 'Latin', tab: 'Ethnicity' },        { src: pg26, title: 'Anime', tab: 'Ethnicity' }    ];
    
    const images2 = [
        { src: age1, title: 'Teen (18+)', tab: 'Age' },        { src: age2, title: 'Twenties', tab: 'Age' },        { src: age3, title: 'Thirties', tab: 'Age' },        { src: age4, title: 'Forties', tab: 'Age' }    ];
    
    const images3 = [        { src: brown3, title: 'Brown', tab: 'Eyes' },        { src: black2, title: 'Black', tab: 'Eyes' },
        { src: green, title: 'Green', tab: 'Eyes' },        { src: gray, title: 'Gray', tab: 'Eyes' },
        { src: blue, title: 'Blue', tab: 'Eyes' }    ];

    const images4 = [        { src: pg21, title: 'Straight', tab: 'Hair Style & Color' },        { src: curly1, title: 'Curly', tab: 'Hair Style & Color' },
        { src: buzzCut, title: 'Buzz Cut', tab: 'Hair Style & Color' },        { src: dreadlocks, title: 'Dreadlocks', tab: 'Hair Style & Color' },   ];
    
    const images5 = [        { src: muscular, title: 'Muscular', tab: 'Body Type' },        { src: slim, title: 'Slim', tab: 'Body Type' }, ];


    const images10 = [        { src: white, title: 'White', tab: 'Ethnicity' },        { src: brown1, title: 'Brown', tab: 'Ethnicity' },
        { src: black1, title: 'Black', tab: 'Ethnicity' }    ];
    
    const images20 = [        { src: age1, title: 'Teen (18+)', tab: 'Age' },        { src: age2, title: 'Twenties', tab: 'Age' },
        { src: age3, title: 'Thirties', tab: 'Age' },        { src: age4, title: 'Forties', tab: 'Age' }    ];
    
    const images30 = [        { src: brown2, title: 'Brown', tab: 'Eyes' },        { src: blueAni, title: 'Blue', tab: 'Eyes' },        { src: greenAni, title: 'Green', tab: 'Eyes' },        { src: blackAni, title: 'Black', tab: 'Eyes' },
        { src: grayAni, title: 'Gray', tab: 'Eyes' }    ];
    
    const images40 = [        { src: white, title: 'Straight', tab: 'Hair Style & Color' },        { src: curly2, title: 'Curly', tab: 'Hair Style & Color' },
        { src: dreadlocksAni, title: 'Dreadlocks', tab: 'Hair Style & Color' },        { src: buzzCutAni, title: 'Buzz Cut', tab: 'Hair Style & Color' },   ];
    
    const images50 = [        { src: white, title: 'Muscular', tab: 'Body Type' },        { src: slimAni, title: 'Slim', tab: 'Body Type' },];


    useEffect(() => {
        setSelectedOptions(groupData);
    }, [currentPage]);

    useEffect(() => {
        if (selectedOptions.group1 && selectedOptions.group2 && selectedOptions.group3) {
            handleNextoptions();
        }
    }, [selectedOptions]);

    const handleOptionSelect = (group, value) => {
        setSelectedOptions(prevState => ({
            ...prevState,
            [group]: value,
        }));
    };

    const handleImageClick = (index) => {
        setSelectedIndex(index);
    };

    const handleBack = () => {
        if (selectedIndex === null) {
            if (currentPage === 1) {
                navigate(-1);
            } else {
                setCurrentPage(currentPage - 1);
            }
        } else {
            setSelectedIndex(null);
        }
    };

    const colors = [
        { name: 'Red', color: 'red' },
        { name: 'Green', color: 'green' },
        { name: 'Pink', color: 'pink' },
        { name: 'Black', color: 'black' },
        { name: 'Brown', color: 'brown' },
        { name: 'Grey', color: 'grey' },
    ];


    const handleNext = (item, selectedColor) => {
        const { title, tab } = item;
        if (currentPage === 1) {
            setStyle(title);
        }
        let newData = { ...gfData, [`gfData${currentPage}`]: { title, tab } };

        if (currentPage >= 1 && currentPage <= 4) {
            setGFdata(newData);
            if (currentPage < 13) {
                setCurrentPage(currentPage + 1);
            } else {
                navigate('/final');
            }
        } else if (currentPage === 5) {
            if (selectedColor == '') {
                toast.error('Select Hair Color First', {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                newData = { ...newData, [`gfData${currentPage}`]: { title, tab, color: selectedColor } };
                setGFdata(newData);
                setCurrentPage(currentPage + 1);
            }
        } else if (currentPage >= 6) {
            // Update state for pages 6 through 8
            setGFdata(newData);
            if (currentPage < 15) {
                setCurrentPage(currentPage + 1);
            } else {
                navigate('/final');
            }
        }
    };


    const pageNames = {
        9: 'Personality',
        10: 'Voice',
        11: 'Occupation',
        12: 'Hobbies',
        13: 'Relationship'
    };

    const getPageName = (pageNumber) => pageNames[pageNumber] || `Page ${pageNumber}`;
    const handleNextoptions = () => {
        let nextPage = currentPage;
        if (currentPage >= 7 && currentPage <= 11) {
            if (Object.values(selectedOptions).every(option => option !== null)) {
                if (currentPage <= 11) {
                    nextPage = currentPage + 1;
                } else {
                    navigate('/final');
                    return;
                }
            }
        }
        setPageSequence(prevSequence => {
            const updatedSequence = [...prevSequence, currentPage, selectedOptions];
            const groupByPage = (sequence) => {
                const grouped = {};
                for (let i = 0; i < sequence.length; i += 2) {
                    const page = sequence[i];
                    const options = sequence[i + 1];
                    if (!grouped[page]) {
                        grouped[page] = [];
                    }
                    grouped[page].push(options);
                }
                return grouped;
            };
            const formatGroupedData = (groupedData) => {
                return Object.keys(groupedData).sort((a, b) => parseInt(a) - parseInt(b)).map(page => ({
                    page: getPageName(parseInt(page)),
                    options: groupedData[page]
                }));
            };
            const groupedData = groupByPage(updatedSequence);
            const formattedData = formatGroupedData(groupedData);

            setOptionsData(formattedData);
            return updatedSequence;
        });

        setCurrentPage(nextPage);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handlefinalInputChange = (e) => {
        setFinalInputValue(e.target.value);
    };

    // Handler for button click
    const handleData = () => {
        if (inputValue.trim() === '') {
            alert("Set GirlFriend Name")
        } else {
            setCurrentPage(currentPage + 1);
        }
    };

    const Generategf = async () => {
        if (finalInputValue.trim() === '') {
            alert('Please enter some text.');
            return;
        }
        const payload = {
            gfData: gfData,
            optionsData: optionsData,
            GFNmae: inputValue,
            Summery: finalInputValue
        };

        try {
            const response = await axios.post(`${BASE_URL}bot_create/`, payload);
            if (response.status === 201) {
                navigate('/Aidreamchat');
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    console.log(style)
    return (
        <>
            <ToastContainer />
            {currentPage === 1 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.
                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>1 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select Style</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>

                        {images0.map((item, index) => (
                            <div
                                key={index}
                                className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                onClick={() => handleImageClick(index)}
                            >
                                <img
                                    src={item.src}
                                    alt={item.title}
                                    className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                />
                                <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item)}>
                                    {item.title}
                                </button>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {currentPage === 2 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.</p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>2 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select Ethnicity</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images1.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images10.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </>
            )}

            {currentPage === 3 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>3 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select Age</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images2.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images20.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>

                </>
            )}

            {currentPage === 4 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>4 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select Eyes</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images3.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images30.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>

                </>
            )}

            {currentPage === 5 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.
                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>5 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Select hair Style & Color*</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 my-5'>
                        {style === 'Realistic' ? (
                            images4.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item, selectedColor)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images40.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item, selectedColor)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>

                    <div className='text-center'>
                        <p className='text-[35px] py-2'> Choose hair Color*</p>
                        <div className='flex justify-between'>
                            {colors.map(({ name, color }) => (
                                <div
                                    key={name}
                                    className={`w-40 h-40 rounded-full inline-flex items-center justify-center text-xl font-bold border-[1px] ${selectedColor === color ? 'border-red-600' : ''}`}
                                    style={{ backgroundColor: color, color: color === 'black' || color === 'brown' ? 'white' : 'gray' }}
                                    onClick={() => handleColorClick(color)}
                                >
                                    {name}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {currentPage === 6 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>6 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span>Choose Body Type</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                        {style === 'Realistic' ? (
                            images5.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        ) : (
                            images50.map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative w-72 h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === index ? 'bg-red-600 border-4 dreamclr' : 'border border-transparent'}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.title}
                                        className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                    />
                                    <button className='dreamclr text-white font-medium rounded-full absolute px-5 py-1 bottom-10 ml-[100px] xl:ml-[100px]' onClick={() => handleNext(item)}>
                                        {item.title}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>

                </>
            )}

            {currentPage === 9 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>9 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose Personality</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group1 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group1', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group2 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group2', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group3 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group3', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {currentPage === 10 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>10 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose Voice</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group1 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group1', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group2 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group2', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group3 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group3', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {currentPage === 11 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>11 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose Occupation</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group1 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group1', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group2 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group2', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group3 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group3', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {currentPage === 12 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>12 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose Hobbies  ( You can choose up to 3 variants )</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group1 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group1', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group2 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group2', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-55 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group3 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-full hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group3', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {currentPage === 13 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>13 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Choose Relationship</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-45 h-60 md:h-45 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group1 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-[10px] hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group1', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-45 h-60 md:h-45 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group2 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-[10px] hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group2', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 mt-5'>
                            {['Small', 'Medium', 'Large', 'Skinny'].map(option => (
                                <div
                                    key={option}
                                    className={`w-80 md:w-45 h-60 md:h-45 m-2 justify-center flex items-center gap-2 px-4 py-2 border-2 bg-[#6161614A] 
                                ${selectedOptions.group3 === option ? 'bg-[#FF00133D] border-red-700 text-red-500' : ''} 
                                border-zinc-600 text-white text-lg rounded-[10px] hover:cursor-pointer`}
                                    onClick={() => {
                                        handleOptionSelect('group3', option);
                                    }}
                                >
                                    <p className='text-center'>{option}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {currentPage === 14 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>14 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Name Your Girlfriend</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='w-[95rem] m-[80px]'>
                        <div className='bg-gray-700 rounded-xl mt-2 px-5 py-3 text-black text-2xl font-light w-full h-38'>
                            <input
                                type="text"
                                className='focus:outline-none text-white bg-gray-700 w-full'
                                name='gfname'
                                placeholder='Beautiful'
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        </div>
                        <p className='my-5'>Name Suggestions:</p>
                        <div className='flex justify-center'>
                            <button
                                className='w-40 bg-red-700 text-white px-5 py-2 rounded mt-[100px]'
                                onClick={handleData}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </>
            )}

            {currentPage === 15 && (
                <>
                    <p className='pt-3 text-black text-xl xl:text-[20px] font-medium'>
                        Looking for a simple chat? Try our Basic AI Girlfriend. Want a deeper connection? Choose the Advanced AI Girlfriend for a more personalized experience.                    </p>
                    <div className='bg-gray-500 flex items-center justify-between rounded-xl mt-[40px] mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38'>
                        <div className='text-xl xl:text-[25px] text-white font-medium text-center'>
                            <span>15 /</span>
                            <span className='text-red-600'>
                                15
                                <span className='inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800'></span>
                            </span>
                            <span> Summary</span>
                        </div>
                        <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                            Back
                        </button>
                    </div>

                    <div className='w-[95rem] my-[50px] mx-[80px]'>
                        <div className='bg-gray-700 rounded-xl mt-2 px-5 py-3 text-black text-2xl font-light w-full h-38'>
                            <textarea
                                type="text"
                                className='focus:outline-none text-white bg-gray-700 w-full h-[200px]'
                                placeholder='Beautiful'
                                value={finalInputValue}
                                onChange={handlefinalInputChange}
                            />
                        </div>
                        <div className='flex justify-center'>
                            <button
                                className='w-80 bg-red-700 text-white px-5 py-2 rounded mt-[100px]'
                                onClick={Generategf}
                            >
                                Bring my Girlfriend to life
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default AIBoyfriend;
