import React, { useState,useEffect } from 'react';
import Recreateimage from '../../components/Recreateimage';
import SelectAIModel from '../../components/SelectAIModel';
import SearchExtension from '../../components/SearchExtension';
import UseShortWordsInputBox from '../../components/UseShortWordsInputBox';
import FooterMakeAI from '../../components/FooterMakeAI';
import { IoSearchOutline } from 'react-icons/io5'
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
import { aiModal1, aiModal2, aiModal3, aiModal4, aiModal5, aiModal6, aiModal7, aiModal8, bigtits,mediumtits,GiantTits,BJ,smalltits,goldE, info } from '../../../assets'

const Simplified = () => {
    const [selectedFeature, setSelectedFeature] = useState('Hair color');
    const [selectedCharacter, setSelectedCharacter] = useState('1 Girl');
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedIndextwo, setSelectedIndextwo] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    */}
    const [selectedIndexAi, setSelectedIndexAi] = useState(null);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 4,
            partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        }
    };

    const data = [
        { img: aiModal8, title: 'Super Realistic Model', model: 'realisticinstav6.safetensors [ecb478bd5e]' },
        { img: aiModal4, title: 'Hyper Realistic Model', model: 'hyperrealisticv6.safetensors [fe7578cb5e]' },
        { img: aiModal5, title: 'Photo Realistic Model', model: 'influncerv2_p1.safetensors [b6a4e767b6]' },
        { img: aiModal1, title: 'Surreal Anime Model', model: 'bestanimface.safetensors [d65f84e1d0]' },
        { img: aiModal2, title: 'Realistic Anime Model', model: 'anniepornbestwithhuman.safetensors [dfc5cbba6c]' },
        { img: aiModal3, title: 'Realistic Human Model', model: 'realisticinstav6.safetensors [ecb478bd5e]' },
        { img: aiModal6, title: 'Hentai Anime Model', model: 'metrixhentaip.safetensors [184d9775e3]' },
        { img: aiModal7, title: 'Dark Anime Model', model: 'dksAnimeMerge_dksAnimeV10.safetensors [a378378a06]' },
    ];

    // Function to set the selected index based on the default model from the API
    const setDefaultModel = async () => {
        try {
            const response = await axios.get('https://api2.eromantic.ai/api/v1/GetDefaultModel/');
            const defaultModel = response.data.sd_model_checkpoint;

            // Find the index of the model in the data array
            const defaultIndex = data.findIndex(item => item.model === defaultModel);

            // If the model is found, set it as the selected index
            if (defaultIndex !== -1) {
                setSelectedIndexAi(defaultIndex);
            }
        } catch (error) {
            console.log("Error fetching default model:", error);
        }
    };

    useEffect(() => {
        // Call the function to fetch the default model when the component is mounted
        setDefaultModel();
    }, []);

    const handleModelSelection = (index) => {
        setSelectedIndexAi(index);

        const selectedModel = data[index].model;

        let requestData = {
            "sd_model_checkpoint": selectedModel
        };

        axios.post('https://api2.eromantic.ai/api/v1/sd_model_setup/', requestData)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    */}

  // Sample data for images and their titles
  const images = [
    { src: smalltits, title: 'Small Tits', trigerWord:'Small Tits', Lora:''},
    { src: mediumtits, title: 'Medium Saggy Tits 2', trigerWord:'Medium Saggy Tits', Lora:''},
    { src: bigtits, title: 'Big Boobs', trigerWord:'Big Tits',Lora:'' },
    { src: GiantTits, title: 'Huge Boobs', trigerWord:'Huge Tits',Lora:'' },
    { src: BJ, title: 'BJ',trigerWord:'penis, blowjob, blowjobTopofheadPovquiron, oral, nsfw, naked, nude, ',Lora:'<lora:quiron_BlowjobTopofHeadPOV_v040320_Lora:0.8>' ,ad_prompt :"((blowjob face:0.8)), ((hyper realistic face:0.5)), blowjobTopofheadPovquiron, penis",},
  ];
   const imagestwo = [
  ];

    const features = [
        { name: 'Hair color' },
        { name: 'Haircut' },
        { name: 'Wearing' },
        { name: 'Body type' },
        { name: 'Nationality' },
        { name: 'Environment' },
        { name: 'Style' },
        { name: 'Effects' },
    ];

   const options = {
    'Characters': [
        { name: '1 Girl' },
        { name: '1 Boy' },
    ],
    '1 Girl': {
        'Hair color': [
            { 'name': 'Jet Black' },
            { 'name': 'Platinum Blonde' },
            { 'name': 'Honey Blonde' },
            { 'name': 'Copper Red' },
            { 'name': 'Silver' },
            { 'name': 'Rose Gold' },
            { 'name': 'Light Brown' },
            { 'name': 'Golden Brown' },
            { 'name': 'Ash Blonde' },
            { 'name': 'Strawberry Blonde' },
            { 'name': 'Chestnut' },
            { 'name': 'Mahogany' },
            { 'name': 'Burgundy' },
            { 'name': 'Gray' },
            { 'name': 'White' },
        ],
        'Haircut': [
            { 'name': 'Long Layers' },
            { 'name': 'Pixie Cut' },
            { 'name': 'Bob Cut' },
            { 'name': 'Lob (Long Bob)' },
            { 'name': 'Feathered Cut' },
            { 'name': 'Curtain Bangs' },
            { 'name': 'Side Swept Bangs' },
            { 'name': 'A-Line Bob' },
            { 'name': 'Shaggy Cut' },
            { 'name': 'Blunt Cut' },
            { 'name': 'Choppy Layers' },
            { 'name': 'French Twist' },
            { 'name': 'Updo' },
            { 'name': 'Braided Ponytail' },
        ],
        'Wearing': [
            { 'name': 'Dress' },
            { 'name': 'Bikini' },
            { 'name': 'Lingerie' },
            { 'name': 'Tank Top' },
            { 'name': 'Jeans' },
            { 'name': 'Skirt' },
            { 'name': 'Evening Gown' },
            { 'name': 'Blouse' },
            { 'name': 'Shorts' },
            { 'name': 'Sweater' },
            { 'name': 'Cardigan' },
            { 'name': 'Romper' },
            { 'name': 'Jumpsuit' },
            { 'name': 'Tunic' },
            { 'name': 'Mini Dress' },
            { 'name': 'Hoodie' },
            { 'name': 'Crop Top' },
            { 'name': 'Maxi Dress' },
            { 'name': 'Overalls' },
            { 'name': 'Peplum Top' },
            { 'name': 'Leggings' },
            { 'name': 'Pajamas' },
            { 'name': 'Robe' },
            { 'name': 'Swimsuit' },
            { 'name': 'Bathrobe' },
            { 'name': 'Kimono' },
            { 'name': 'Bodycon Dress' },
            { 'name': 'Denim Jacket' },
            { 'name': 'Bomber Jacket' },
            { 'name': 'Leather Jacket' },
            { 'name': 'Blazer' },
            { 'name': 'Pullover' },
            { 'name': 'T-Shirt Dress' },
            { 'name': 'Pencil Skirt' },
            { 'name': 'Pleated Skirt' },
            { 'name': 'Slip Dress' },
            { 'name': 'Dungarees' },
            { 'name': 'Sarong' },
            { 'name': 'Poncho' },
        ],
        'Body type': [
            { 'name': 'Slim' },
            { 'name': 'Athletic' },
            { 'name': 'Curvy' },
            { 'name': 'Petite' },
            { 'name': 'Hourglass' },
            { 'name': 'Pear Shaped' },
            { 'name': 'Tall' },
            { 'name': 'Plus Size' },
        ],
        'Nationality': [
            { 'name': 'American' },
            { 'name': 'Asian' },
            { 'name': 'European' },
            { 'name': 'Brazilian' },
            { 'name': 'Italian' },
            { 'name': 'French' },
            { 'name': 'Russian' },
            { 'name': 'Indian' },
            { 'name': 'Japanese' },
            { 'name': 'Korean' },
            { 'name': 'Mexican' },
            { 'name': 'Spanish' },
            { 'name': 'Australian' },
            { 'name': 'Argentinian' },
            { 'name': 'Canadian' },
        ],
        'Environment': [
            { 'name': 'Beach' },
            { 'name': 'City' },
            { 'name': 'Fantasy' },
            { 'name': 'Nature' },
            { 'name': 'Urban' },
            { 'name': 'Island' },
            { 'name': 'Garden' },
            { 'name': 'Mountain' },
            { 'name': 'Forest' },
            { 'name': 'Countryside' },
            { 'name': 'Desert' },
            { 'name': 'River' },
            { 'name': 'Waterfall' },
            { 'name': 'Snowy' },
        ],
        'Style': [
            { 'name': 'Realistic' },
            { 'name': 'Fantasy' },
            { 'name': 'Anime' },
            { 'name': 'Digital Art' },
            { 'name': 'Photorealism' },
            { 'name': 'Pop Art' },
            { 'name': 'Vintage' },
            { 'name': 'Surreal' },
            { 'name': 'Minimalist' },
            { 'name': 'Expressionist' },
            { 'name': 'Cubist' },
            { 'name': 'Gothic' },
            { 'name': 'Renaissance' },
        ],
        'Effects': [
            { 'name': 'Lighting' },
            { 'name': 'Shadows' },
            { 'name': 'Bloom' },
            { 'name': 'Lens Flare' },
            { 'name': 'Vignette' },
            { 'name': 'Soft Focus' },
            { 'name': 'Color Grading' },
            { 'name': 'Depth of Field' },
            { 'name': 'Bokeh' },
            { 'name': 'Glitch' },
            { 'name': 'Film Grain' },
            { 'name': 'Sepia' },
            { 'name': 'HDR' },
            { 'name': 'Vintage Effect' },
        ],
    },
    '1 Boy': {
        'Hair color': [
            { 'name': 'Jet Black' },
            { 'name': 'Dark Brown' },
            { 'name': 'Medium Brown' },
            { 'name': 'Ash Brown' },
            { 'name': 'Blonde' },
            { 'name': 'Silver' },
            { 'name': 'Golden Blonde' },
            { 'name': 'Light Brown' },
            { 'name': 'Chestnut' },
            { 'name': 'Mahogany' },
            { 'name': 'Copper Red' },
            { 'name': 'Platinum Blonde' },
            { 'name': 'Gray' },
        ],
        'Haircut': [
            { 'name': 'Buzz Cut' },
            { 'name': 'Crew Cut' },
            { 'name': 'Undercut' },
            { 'name': 'Fade' },
            { 'name': 'Pompadour' },
            { 'name': 'Quiff' },
            { 'name': 'Side Part' },
            { 'name': 'High and Tight' },
            { 'name': 'Ivy League' },
            { 'name': 'Mohawk' },
            { 'name': 'Faux Hawk' },
            { 'name': 'Flat Top' },
            { 'name': 'Short Crop' },
            { 'name': 'Spiky' },
        ],
        'Wearing': [
            { 'name': 'T-shirt' },
            { 'name': 'Jeans' },
            { 'name': 'Hoodie' },
            { 'name': 'Jacket' },
            { 'name': 'Shorts' },
            { 'name': 'Suit' },
            { 'name': 'Sweater' },
            { 'name': 'Blazer' },
            { 'name': 'Cardigan' },
            { 'name': 'Tank Top' },
            { 'name': 'Polo Shirt' },
            { 'name': 'Cargo Pants' },
            { 'name': 'Tracksuit' },
            { 'name': 'Leather Jacket' },
            { 'name': 'V-Neck' },
        ],
        'Body type': [
            { 'name': 'Slim' },
            { 'name': 'Athletic' },
            { 'name': 'Muscular' },
            { 'name': 'Average' },
            { 'name': 'Broad Shouldered' },
            { 'name': 'Tall' },
            { 'name': 'Short' },
            { 'name': 'Stocky' },
        ],
        'Nationality': [
            { 'name': 'American' },
            { 'name': 'Asian' },
            { 'name': 'European' },
            { 'name': 'Brazilian' },
            { 'name': 'Russian' },
            { 'name': 'African' },
            { 'name': 'Australian' },
            { 'name': 'Canadian' },
            { 'name': 'Mexican' },
            { 'name': 'Italian' },
            { 'name': 'French' },
            { 'name': 'German' },
            { 'name': 'British' },
            { 'name': 'Indian' },
            { 'name': 'Chinese' },
        ],
        'Environment': [
            { 'name': 'Urban' },
            { 'name': 'Nature' },
            { 'name': 'City' },
            { 'name': 'Forest' },
            { 'name': 'Mountain' },
            { 'name': 'Beach' },
            { 'name': 'Desert' },
            { 'name': 'Snowy' },
            { 'name': 'Countryside' },
            { 'name': 'Village' },
            { 'name': 'Futuristic City' },
            { 'name': 'Cave' },
            { 'name': 'Jungle' },
            { 'name': 'Outer Space' },
        ],
        'Style': [
            { 'name': 'Realistic' },
            { 'name': 'Cartoon' },
            { 'name': 'Abstract' },
            { 'name': 'Minimalist' },
            { 'name': 'Anime' },
            { 'name': 'Digital Art' },
            { 'name': 'Sci-Fi' },
            { 'name': 'Steampunk' },
            { 'name': 'Noir' },
            { 'name': 'Futuristic' },
            { 'name': 'Graffiti' },
            { 'name': '3D Rendering' },
        ],
        'Effects': [
            { 'name': 'Shadows' },
            { 'name': 'HDR' },
            { 'name': 'Glitch' },
            { 'name': 'Vibrance' },
            { 'name': 'Color Grading' },
            { 'name': 'Sepia' },
            { 'name': 'Lens Flare' },
            { 'name': 'Pixelation' },
            { 'name': 'Motion Blur' },
            { 'name': 'Distortion' },
            { 'name': 'Negative' },
            { 'name': 'Tilt-Shift' },
        ],
    },
};
    const [selectedFeatures, setSelectedFeatures] = useState(options);
{/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    */}
    const [selected, setSelected] = useState('medium');
    const [aiselected, setaiSelected] = useState('creative');
    const [MagicSelected, setMagicSelected] = useState('none');
    const [svgselected, setsvgSelected] = useState('fourth');
    const [otherInput, setOtherInput] = useState(null); // Add state for input value
    const [FaceoptionalInput, setFaceoptionalInput] = useState(null); // Add state for input value


      const MagicClick = (value) => {
        setMagicSelected(value);
      };
      const handleClick = (value) => {
        setSelected(value);
      };
      const handleaiClick = (value) => {
        setaiSelected(value);
      };

       const handlesvgClick = (value) => {
        setsvgSelected(value);
      };

       const handleOtherInputChange = (e) => {
            setOtherInput(e.target.value); // Update state when input changes
          };
   const handleFaceoptionalInputChange = (e) => {
        setFaceoptionalInput(e.target.value); // Update state when input changes
      };

  const navigate = useNavigate()
//  const generate = () => {
//   navigate('/dashboard/ImageView')
//  }

    {/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    */}

const generate = () => {
    const selectedHairColor = typeof selectedFeatures[selectedCharacter]["Hair color"] === 'string' ? selectedFeatures[selectedCharacter]["Hair color"] : null;
    const selectedHaircut = typeof selectedFeatures[selectedCharacter]["Haircut"] === 'string' ? selectedFeatures[selectedCharacter]["Haircut"] : null;
    const selectedWearing = typeof selectedFeatures[selectedCharacter]["Wearing"] === 'string' ? selectedFeatures[selectedCharacter]["Wearing"] : null;
    const selectedBodyType = typeof selectedFeatures[selectedCharacter]["Body type"] === 'string' ? selectedFeatures[selectedCharacter]["Body type"] : null;
    const selectedNationality = typeof selectedFeatures[selectedCharacter]["Nationality"] === 'string' ? selectedFeatures[selectedCharacter]["Nationality"] : null;
    const selectedEnvironment = typeof selectedFeatures[selectedCharacter]["Environment"] === 'string' ? selectedFeatures[selectedCharacter]["Environment"] : null;
    const selectedStyle = typeof selectedFeatures[selectedCharacter]["Style"] === 'string' ? selectedFeatures[selectedCharacter]["Style"] : null;
    const selectedEffects = typeof selectedFeatures[selectedCharacter]["Effects"] === 'string' ? selectedFeatures[selectedCharacter]["Effects"] : null;


  const promptString = Object.values({
      character: selectedCharacter || null,
      hair_color: selectedHairColor ? selectedHairColor + " hair" : null,
      haircut: selectedHaircut ? selectedHaircut + " hair" : null,
      wearing: selectedWearing ? "Wearing " + selectedWearing : null,
      body_type: selectedBodyType ? selectedBodyType + " body" : null,
      nationality: selectedNationality || null,
      environment: selectedEnvironment ? "in a " + selectedEnvironment : null,
      style: selectedStyle ? selectedStyle + " style" : null,
      effects: selectedEffects ? selectedEffects + " effect" : null,
      extensions: selectedIndex ? "(" + selectedIndex + ")" : null,
      other: otherInput || null,
      face_prompt: FaceoptionalInput || null
    })
    .filter(value => value !== null)
    .join(', ');


    console.log(promptString)

  // Other arguments
  var newDate = new Date();

  var datetime = newDate.getDate() + "_" + newDate.getMonth()+ "_" +newDate.getFullYear() +"_" +newDate.getTime();
  const timestamp = new Date().getTime(); // Current timestamp
  const randomString = Math.random().toString(36).substring(2, 10); // Random string of 8 characters
  const requestPayload = {
    generation_prompt: promptString,
    steps: selected,
    make_ai_listen: aiselected,
    magic: MagicSelected,
    aspect_ratio: svgselected,
    variations: null,
    make_private: false,
    user_name:localStorage.getItem('login'),
    generation_id:"ER_GenImg_"+datetime,
    generation_slug:"ER_"+selectedIndexAi+"_"+promptString.replaceAll(', ','_').replaceAll(' ','_'),
    Aimodel:selectedIndexAi,
    extensions:selectedIndex,
    face_prompt:FaceoptionalInput,
    image_file_name:`ER_image_${timestamp}_${randomString}.png`,
    other:otherInput,
  };
  console.log(requestPayload);

  axios.post('https://api2.eromantic.ai/api/v1/generate_image/', requestPayload)
    .then(response => {
      navigate('/dashboard/ImageView/ER_GenImg_'+datetime);
    })
    .catch(error => {
      console.log("Error generating image:", error);
    });
};
//const choosefeature=(item)=> {
//    setSelectedItem(item)
//};

const choosefeature = (featureName, featureType) => {
  setSelectedFeatures((prev) => ({
    ...prev,
    [selectedCharacter]: {
      ...prev[selectedCharacter],
      [featureType]: featureName,
    },
  }));
};
console.log(selectedFeatures,"fthfh",selectedCharacter);

    return (
        <>
            <p className='pt-5 text-xl xl:text-[25px] font-medium'>The basic version is for beginners. If you want more options and control, choose 'advanced' above. The simple version is easier to use, but the advanced one gives you more specific results.</p>
            <Recreateimage />

            <div className='pt-10 md:pt-20'>
                <div className='flex justify-center gap-5 pb-2'>
                    <p className='font-semibold text-3xl xl:text-4xl pb-5'>Select AI Model</p>
                    <img src={info} alt="infoBlack" className='h-6' />
                </div>
                <div>
                    <Carousel
                        partialVisible={true}
                        responsive={responsive}
                        style={{ zIndex: 1 }}
                    >
                        {
                            data.map((item, index) => (
                                <div
                                    key={index}
                                    className='relative flex flex-col items-center cursor-pointer'
                                    onClick={() => handleModelSelection(index)}
                                >
                                    <img
                                        src={item.img}
                                        alt={`aiModal${index}`}
                                        className={`w-[300px] h-[360px] xl:w-[270px] xl:h-[360px] rounded-lg ${selectedIndexAi === index ? 'border-4 border-red-600 shadow-lg shadow-red-600' : ''}`}
                                    />
                                    <div
                                        className={`w-[300px] xl:w-[270px] absolute bottom-0 right-0 bg-black bg-opacity-50 text-black text-center py-2 rounded-b-lg ${selectedIndexAi === index ? 'bg-red-600' : ''}`}
                                        style={{ left: "3.5rem" }}
                                    >
                                        {item.title}
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>

            <div className='flex justify-center gap-5 pt-10 md:pt-20'>
                <p className='font-semibold text-3xl xl:text-4xl pb-5'>Select Character</p>
                <img src={info} alt="infoBlack" className='h-6 bg-black rounded-full' />
            </div >
                <div className="flex justify-center space-x-6">
                {options['Characters'].map((item, index) => (
                <div
                    key={index}
                    className='text-white bg-black py-3 rounded-xl items-center flex flex-col md:flex-row justify-center space-x-4 m-4'
                >
                    <p
                        className={`font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4 ${
                            selectedCharacter === item.name ? 'text-red-600' : ''
                        }`}
                        onClick={() => {
                            setSelectedCharacter(item.name);
                            setSelectedFeature('Hair color');
                        }}
                    >
                        {item.name}
                    </p>
                </div>
            ))}
            </div>



            {selectedCharacter && (
                <>
                    <div className='flex justify-center gap-5 pt-10 md:pt-20'>
                        <p className='font-semibold text-3xl xl:text-4xl pb-5'>Select Features</p>
                        <img src={info} alt="infoBlack" className='h-6' />
                    </div >
                   <div className='bg-white text-black py-3 rounded-xl justify-around items-center flex flex-wrap'>
                        {features.map((item, index) => (
                            <p
                                key={index}
                                className={`font-semibold hover:text-red-600 hover:cursor-pointer text-2xl px-4 ${
                                    selectedFeature === item.name ? 'text-red-600' : ''
                                }`}
                                onClick={() => setSelectedFeature(item.name)}
                            >
                                {item.name}
                            </p>
                        ))}
                    </div>

                     {selectedFeature && (
                        <div className='text-black justify-center lg:justify-start items-center flex gap-2 flex-wrap mt-5'>
                            {options[selectedCharacter][selectedFeature].map((item, index) => (
                              <div
                                key={index}
                                className={`w-[190px] border-2 hover:text-red-600 hover:border hover:border-red-600 rounded-full mt-3 hover:cursor-pointer ${
                                  selectedFeatures[selectedCharacter]?.[selectedFeature] === item.name
                                    ? 'text-red-600 border-red-600'
                                    : 'border-zinc-600'
                                }`}
                                onClick={() => choosefeature(item.name, selectedFeature)}
                              >
                                <p className='font-semibold text-2xl py-2 px-4 text-center'>
                                  {item.name}
                                </p>
                              </div>
                            ))}


                            {/* <div className='w-[150px] mt-3 border-2 border-zinc-600 hover:text-red-600 hover:border hover:border-red-600 rounded-full hover:cursor-pointer '>
                                <p className='font-semibold hover:text-red-600 text-2xl py-2 px-4 text-center'>Custom</p>
                            </div> */}
                        </div>
                    )}
                </>
            )}



<div className='flex justify-center gap-5 pt-10 md:pt-20'>
                <p className='font-semibold text-3xl xl:text-4xl pb-5'>Extensions</p>
                <img src={info} alt="infoBlack" className='h-6 bg-black rounded-full' />
            </div>
            <div className='bg-white rounded-xl py-6 px-6 '>
                <div className='px-5 py-2 flex border border-black w-full items-center rounded-full'>
                    <IoSearchOutline color='black' size={23} /> <input type="search" placeholder='Search Extension' className='focus:outline-none w-full text-black text-xl ps-3' />
                </div>
                <div
                  style={{
                    height: '30rem',
                    overflowY: 'scroll',
                    scrollbarWidth: 'thin', // For Firefox
                    scrollbarColor: '#c0c0c0 #f0f0f0', // For Firefox
                    borderRadius: '0.75rem', // Rounded corners
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                    padding: '1rem', // Padding inside the div
                  }}
                  className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 hover:scrollbar-thumb-gray-500"
                >
                <div className='bg-zinc-300 rounded-full w-32 my-5 py-2 font-semibold text-xl text-center text-gray-600'>
                    Boobs
                </div>
                 <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12'>
                  {images.map((item, index) => (
                  <div
                      key={index}
                      className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndex === item.trigerWord + item.Lora ? 'bg-red-600 border-4 border-red-600' : ''}`}
                      onClick={() => setSelectedIndex(item.trigerWord + item.Lora)}
                    >
                      {/* Image element */}
                      <img
                        src={item.src}
                        alt={item.title}
                        className='w-full h-full object-cover cursor-pointer rounded-lg'
                      />

                      {/* Title overlay */}
                      <div
                        className={`absolute bottom-0 right-0 bg-black bg-opacity-50 text-black text-center py-2 rounded-b-lg ${selectedIndex === index ? 'bg-red-600' : ''}`}
                        style={{ left: "0.01rem" }}
                      >
                        {item.title}
                      </div>

                  </div>
                ))}

                </div>
                <div className='bg-zinc-300 rounded-full w-32 my-5 py-2 font-semibold text-xl text-center text-gray-600'>
                    Ass
                </div>
                 <div className='flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12'>
                 <p className="text-black"> More Extensions On the Way.. </p>
                  {imagestwo.map((item, index) => (
                     <div
                    key={index}
                    className={`relative w-[180px] h-[210px] xl:w-[170px] xl:h-[210px] cursor-pointer rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${selectedIndextwo === index ? 'bg-red-600 border-4 border-red-600' : ''}`}                    onClick={() => setSelectedIndextwo(index)}
                    >
                      {/* Image element */}
                      <img
                      src={item.src}
                      alt={item.title}
                      className='w-full h-full object-cover cursor-pointer rounded-lg'
                    />

                      {/* Title overlay */}
                      <div
                        className={`absolute bottom-0 right-0 bg-black bg-opacity-50 text-black text-center py-2 rounded-b-lg ${selectedIndextwo === index ? 'bg-red-600' : ''}`}                      style={{ left: "0.01rem" }}
                    >
                        {item.title}
                      </div>
                    </div>
                  ))}
                </div>
                </div>
            </div>


            <div className='flex justify-center gap-5 mt-10'>
                <p className='font-semibold text-3xl xl:text-4xl md:pb-5'>Other (use prompt words with commas)</p>
                <img src={info} alt="infoBlack" className='h-6' />
            </div>
            <div className='flex justify-end'>
                <p className='text-end border-b w-fit hover:cursor-pointer hover:text-red-600 hover:boreder-b  hover:border-red-600'>Clear input</p>
            </div>
            <div className='bg-zinc-300 rounded-xl mt-2 px-5 py-3 text-black text-2xl font-light w-full h-38'>
                <input
              type="text"
              className='focus:outline-none bg-zinc-300 w-full'
              placeholder='e.g. smiling, sunny weather'
              value={otherInput} // Bind the input to state
              onChange={handleOtherInputChange} // Handle input change
            />
            </div>

            <div className='flex justify-center gap-5 mt-10'>
                <p className='font-semibold text-3xl xl:text-4xl md:pb-5'>Face prompt (optional)</p>
                <img src={info} alt="infoBlack" className='h-6' />
            </div>
            <div className='flex justify-end'>
                <p className='text-end border-b w-fit hover:cursor-pointer hover:text-red-600 hover:boreder-b  hover:border-red-600'>Clear input</p>
            </div>
            <div className='bg-zinc-300 rounded-xl mt-2 px-5 py-3 text-black text-2xl font-light w-full h-38'>
                <input type="text" className='focus:outline-none bg-zinc-300 w-full' placeholder='e.g. beautiful' value={FaceoptionalInput} // Bind the input to state
              onChange={handleFaceoptionalInputChange} // Handle input change
            />
            </div>


            <div>
                <div className='flex flex-wrap justify-between pt-20'>
                    <div className='bg-white flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans'>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Steps</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                <button
                                    onClick={() => handleClick('medium')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold px-4 py-2 rounded border border-transparent transition-colors duration-300 ${selected === 'medium' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    Medium
                                  </button>
                                  <button
                                    onClick={() => handleClick('high')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold px-4 py-2 rounded border border-transparent transition-colors duration-300 ${selected === 'high' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    High
                                  </button>
                                  <button
                                    onClick={() => handleClick('very-high')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold px-4 py-2 rounded border border-transparent transition-colors duration-300 ${selected === 'very-high' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    Very High
                                  </button>
                                  <button
                                    onClick={() => handleClick('extremely-high')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold px-4 py-2 rounded border border-transparent transition-colors duration-300 ${selected === 'extremely-high' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    Extremely High
                                  </button>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Magic</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                 <p
                                    onClick={() => MagicClick('none')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer transition-colors duration-300 ${MagicSelected === 'none' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    None
                                  </p>
                                  <p
                                    onClick={() => MagicClick('normal')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer transition-colors duration-300 ${MagicSelected === 'normal' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    Normal
                                  </p>
                                  <p
                                    onClick={() => MagicClick('high')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer transition-colors duration-300 ${MagicSelected === 'high' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    High
                                  </p>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Variations</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <input type="number" className='focus:outline-none bg-zinc-300 rounded-xl px-5 py-1 mt-3 w-52 text-black text-2xl' />
                        </div>
                        <div className='flex items-center gap-5 md:mb-3 lg:mb-0'>
                            <input
                                type="radio"
                                className='w-7 h-7'
                            />
                            <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make Private</p>
                        </div>
                    </div>
                    <div className='bg-white flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans'>
                        <div className='mt-3'>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Make AI listen</p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 lg:pt-3'>
                                 <p
                                    onClick={() => handleaiClick('creative', 'Let it be creative')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-semibold cursor-pointer px-4 py-2 rounded border border-transparent transition-colors duration-300 ${aiselected === 'creative' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    Let it be creative
                                  </p>
                                  <p
                                    onClick={() => handleaiClick('normal', 'Normal A lot')}
                                    className={`text-base md:text-lg lg:text-xl xl:text-2xl font-light cursor-pointer px-4 py-2 rounded border border-transparent transition-colors duration-300 ${aiselected === 'normal' ? 'text-red-500' : 'text-black'}`}
                                  >
                                    Normal A lot
                                  </p>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center gap-5'>
                                <p className='text-base md:text-[25px] lg:text-[33px] xl:text-[35px]'>Aspect ratio <span className='text-base md:text-lg lg:text-xl xl:text-2xl'>(square, horizontal, vertical)</span></p>
                                <div>
                                    <img src={info} alt="info" className='h-4 md:h-6' />
                                </div>
                            </div>
                            <div className='bg-[#d4d4d8] rounded-[20px] flex items-center gap-5 text-center mt-2 p-5'>
                            <svg
                                onClick={() => handlesvgClick('first')}
                                className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'first' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                                width="42"
                                height="42"
                                viewBox="0 0 42 42"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect x="1.5" y="1.5" width="39" height="39" rx="6.5" strokeWidth="2"></rect>
                              </svg>
                              <svg
                                onClick={() => handlesvgClick('second')}
                                className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'second' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                                width="62"
                                height="42"
                                viewBox="0 0 62 42"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect x="1.5" y="1.5" width="59" height="39" rx="6.5" strokeWidth="2"></rect>
                              </svg>
                              <svg
                                onClick={() => handlesvgClick('third')}
                                className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'third' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                                width="29"
                                height="42"
                                viewBox="0 0 29 42"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect x="1.5" y="1.5" width="26" height="39" rx="6.5" strokeWidth="2"></rect>
                              </svg>
                              <svg
                                onClick={() => handlesvgClick('fourth')}
                                className={`cursor-pointer fill-none stroke-white mr-[1rem] transition-opacity duration-300 ${svgselected === 'fourth' ? 'opacity-[1]' : 'opacity-[0.4]'}`}
                                width="29"
                                height="42"
                                viewBox="0 0 29 42"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect x="1.5" y="1.5" width="26" height="39" rx="6.5" strokeWidth="2"></rect>
                                <path d="M5.399 22.194V21.459L9.158 16.65H10.292L6.5645 21.459L6.029 21.291H11.8985V22.194H5.399ZM9.4835 24V22.194L9.515 21.291V19.695H10.502V24H9.4835ZM14.7627 19.821C14.5667 19.821 14.3987 19.7545 14.2587 19.6215C14.1187 19.4815 14.0487 19.3065 14.0487 19.0965C14.0487 18.8865 14.1187 18.715 14.2587 18.582C14.3987 18.449 14.5667 18.3825 14.7627 18.3825C14.9517 18.3825 15.1127 18.449 15.2457 18.582C15.3857 18.715 15.4557 18.8865 15.4557 19.0965C15.4557 19.3065 15.3857 19.4815 15.2457 19.6215C15.1127 19.7545 14.9517 19.821 14.7627 19.821ZM14.7627 24.063C14.5667 24.063 14.3987 23.9965 14.2587 23.8635C14.1187 23.7235 14.0487 23.5485 14.0487 23.3385C14.0487 23.1285 14.1187 22.957 14.2587 22.824C14.3987 22.691 14.5667 22.6245 14.7627 22.6245C14.9517 22.6245 15.1127 22.691 15.2457 22.824C15.3857 22.957 15.4557 23.1285 15.4557 23.3385C15.4557 23.5485 15.3857 23.7235 15.2457 23.8635C15.1127 23.9965 14.9517 24.063 14.7627 24.063ZM20.2916 24.084C19.7736 24.084 19.2696 24.0035 18.7796 23.8425C18.2966 23.6815 17.9011 23.4645 17.5931 23.1915L18.0761 22.362C18.3211 22.593 18.6396 22.782 19.0316 22.929C19.4236 23.076 19.8401 23.1495 20.2811 23.1495C20.8411 23.1495 21.2716 23.0305 21.5726 22.7925C21.8736 22.5545 22.0241 22.236 22.0241 21.837C22.0241 21.564 21.9576 21.326 21.8246 21.123C21.6916 20.92 21.4606 20.766 21.1316 20.661C20.8096 20.549 20.3651 20.493 19.7981 20.493H18.1286L18.5171 16.65H22.6646V17.5635H18.9056L19.4516 17.049L19.1471 20.0835L18.6011 19.5795H20.0186C20.7536 19.5795 21.3451 19.674 21.7931 19.863C22.2411 20.052 22.5666 20.3145 22.7696 20.6505C22.9726 20.9795 23.0741 21.361 23.0741 21.795C23.0741 22.215 22.9726 22.6 22.7696 22.95C22.5666 23.293 22.2586 23.5695 21.8456 23.7795C21.4396 23.9825 20.9216 24.084 20.2916 24.084Z" fill="white"></path>
                              </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <button onClick={generate} className='w-full mt-5 rounded-xl text-xl lg:text-3xl bg-red-600 hover:bg-red-800 py-4 text-center flex items-center gap-5 justify-center'>
                    Generate for 1.00
                    <img src={goldE} alt="goldE" />
                </button>
            </div>
        </>
    );
}

export default Simplified;
